import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useMetadataAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const singleMetadata = ref({})
  const isLoading = ref(false)
  const collection = 'metadata'

  const create = async (data) => {
    isLoading.value = true

    try {
      // Get metadata options
      const query = { _id: ObjectId(data.nameId.value) }
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      const metadataOptionsSet = new Set(item.options || [])
      metadataOptionsSet.add(data.optionSelected.value)
      const metadataOptions = Array.from(metadataOptionsSet)

      // Update metadata options with new option added
      const payload = { options: metadataOptions }
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.metadata_option_added'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.metadata_option_add_error'))
    } finally {
      router.push({ name: 'organization-metadata-list' })
      isLoading.value = false
    }
  }

  return {
    create,
    singleMetadata,
    isLoading
  }
}
