<template lang="pug">
div
  Form(
    :data="singleMetadata",
    :metadataFieldDisabled="false",
    :isLoading="isLoading",
    :uploadMode="false",
    @submit="create"
  )
</template>

<script>
import Form from '../shared/Form.vue'
import useMetadataAdd from './useMetadataAdd'

export default {
  components: {
    Form,
  },
  setup() {
    const { create, singleMetadata, isLoading } = useMetadataAdd()

    return {
      singleMetadata,
      create,
      isLoading,
    }
  },
}
</script>
